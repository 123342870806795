@import 'modules/variables';
@import 'modules/mixins';

@import 'base/icons';

@import 'modules/type';
@import 'modules/utilities';

@import 'components/pebble-profile';
@import 'components/onboarding';

@import 'vendors/font-awesome';

@import 'layouts/empty';
@import 'base/shame';

// duplication - to remove as soon as redesign is done
html {
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

;@import "sass-embedded-legacy-load-done:1796";