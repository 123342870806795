.-font-light {
  @include text-font-light;
}

.-surrounded-by-line {
  @include surrounded-by-line;
}

.-mw-360 {
  max-width: 360px;
}

.-mw-sm-360 {
  @include media-breakpoint-down(md) {
    max-width: 360px;
  }
}

// Setting width to lower recommended line length of approx 75 characters
.-mw-text-max {
  max-width: 35em;
}

;@import "sass-embedded-legacy-load-done:1912";