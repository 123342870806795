@import '~bootstrap/scss/functions';

// Colors

$white: #ffffff;

$gray-100: #f6f7f9;
$gray-200: #f0f2f5;
$gray-300: #e3e5e8;
$gray-400: #d4d8dd;
$gray-500: #c2c5cb;
$gray-600: #a5a9b1;
$gray-700: #8c8e97;
$gray-800: #555862;
$gray-900: #2e3138;
$black: #17181c;
$carbon: $black;

$blue: #046ae7;
$purple: #9335e6;
$pink: #da1a55;
$red: #ff4c58;
$orange: #ff7614;
$yellow: #ffbf1f;
$green: #09ba73;

$info-bg-subtle: #f0f8ff;
$warning-border-subtle: #feeab8;

$info-border-subtle: #d7edff;
$warning-bg-subtle: #fff8e5;

$colors: (
  'blue': $blue,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'white': $white,
  'carbon': $carbon,
);

$blue-a25: rgba(2, 116, 225, 0.25);
$blue-100: #f0f8ff;
$blue-300: #d7edff;
$blue-500: $blue;
$blue-400: mix($blue-300, $blue-500, 50%);
$blue-700: #003194;
$blue-600: mix($blue-500, $blue-700, 50%);
$blue-900: #00185c;
$blue-800: mix($blue-700, $blue-900, 50%);

$purple-a25: rgba(147, 53, 230, 0.25);
$purple-100: #f8f1fd;
$purple-300: #e3c8f9;
$purple-500: $purple;
$purple-700: #6107a2;
$purple-900: #340057;

$pink-a25: rgba(218, 26, 85, 0.25);
$pink-100: #fdf1f6;
$pink-300: #fac6dd;
$pink-500: $pink;
$pink-700: #a2063d;
$pink-900: #6b0134;

$red-a25: rgba(255, 76, 88, 0.25);
$red-100: #fff0f1;
$red-300: #ffdbde;
$red-500: $red;
$red-700: #c91d28;
$red-900: #720910;

$orange-a25: rgba(255, 118, 20, 0.25);
$orange-100: #fff3eb;
$orange-300: #ffd8bd;
$orange-500: $orange;
$orange-700: #c25700;
$orange-900: #662500;

$yellow-a25: rgba(255, 191, 31, 0.25);
$yellow-100: #fff8e6;
$yellow-300: #feeab8;
$yellow-500: $yellow;
$yellow-700: #9d6102;
$yellow-900: #4d3400;

$green-a25: rgba(0, 199, 141, 0.25);
$green-100: #edfdf4;
$green-300: #d4f7df;
$green-500: $green;
$green-700: #098661;
$green-900: #003d32;

$primary: $green-700;
$primary-dark: $green-900;
$secondary: $carbon;
$success: $green-700;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $carbon;

// Colors https://getbootstrap.com/docs/4.0/getting-started/theming/#color
// Leaving these as variables to not refactor all the styles to remove them

$min-contrast-ratio: 1.6; // doing this for color to be orange and text to be white but needs discussion

$custom-colors: (
  'warning': $warning,
  'primary': $primary,
  'primary-dark': $primary-dark,
  'white': $white,
  // BLUE
  'blue-a25': $blue-a25,
  'blue-100': $blue-100,
  'blue-300': $blue-300,
  'blue-400': $blue-400,
  'blue-500': $blue-500,
  'blue-600': $blue-600,
  'blue-700': $blue-700,
  'blue-800': $blue-800,
  'blue-900': $blue-900,
  // GRAY
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
  'dark': $black,
  // GREEN
  'green-100': $green-100,
  'green-300': $green-300,
  'green-500': $green-500,
  'green-700': $green-700,
  'green-900': $green-900,
  // ORANGE
  'orange-100': $orange-100,
  'orange-300': $orange-300,
  'orange-500': $orange,
  'orange-700': $orange-700,
  'orange-900': $orange-900,
  // PINK
  'pink-100': $pink-100,
  'pink-300': $pink-300,
  'pink-500': $pink-500,
  'pink-700': $pink-700,
  'pink-900': $pink-900,
  // YELLOW
  'yellow-a25': $yellow-a25,
  'yellow-100': $yellow-100,
  'yellow-300': $yellow-300,
  'yellow-500': $yellow-500,
  'yellow-700': $yellow-700,
  'yellow-900': $yellow-900,
  // PURPLE
  'purple-100': $purple-100,
  'purple-300': $purple-300,
  'purple-500': $purple-500,
  'purple-700': $purple-700,
  'purple-900': $purple-900,
  // RED - errors only
  'red-100': $red-100,
  'red-300': $red-300,
  'red-500': $red-500,
  'red-700': $red-700,
  'red-900': $red-900,
);

$body-color: $carbon;
$line-height-base: 1.43;
$text-muted: #777; // legacy value

$grid-breakpoints: (
  // If updating, update also in styleConstants.ts
  xs: 0,
  sm: 425px,
  md: 768px,
  lg: 992px,
  xl: 1152px,
  xxl: 1400px
);

$border-base: var(--bs-gray-400);
$border-hover: var(--border-hover);
$border-focus-active: var(--bs-gray-700);
$border-translucent: transparentize($gray-800, 0.75);
$border-color: $border-base;

$small-font-size: 0.75rem; // approximately 12px
$font-size-base: 0.875rem; // approximately 14px
$h5-font-size: 1rem; // approximately 16px

// custom breakpoints
$screen-mail-md: 640px;

$screen-method-one: 700px;
$screen-method-two: 720px;

$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-gutter-width: 30px;

// bootstrap 5 upgrade

$enable-caret: false;
$enable-negative-margins: true;

$link-hover-color: $green-700;
// Pagination
$pagination-active-color: var(--bs-white);
$pagination-active-bg: var(--bs-primary);
$pagination-active-border-color: var(--bs-primary);
$pagination-color: var(--bs-primary);

// hr
$hr-opacity: 1;
$hr-color: #eee; // keep as was before migration

// Nav items
$nav-link-color: var(--bs-primary);
$nav-link-hover-color: $green-700;
$nav-pills-link-active-bg: #d3d3d3; // color on info panel but not present on current grays
$nav-pills-link-active-color: $green-700;
$nav-pills-border-radius: 0 4px 4px 0;

// Nav bar
$navbar-light-toggler-border-color: none;
$navbar-toggler-focus-width: 0;

// // Modals
$modal-xl: 1140px;
$modal-lg: 900px;
$modal-md: 640px;
$modal-sm: 500px;
$modal-xs: 350px;

// Buttons
$btn-link-color: var(--bs-primary);
$btn-link-hover-color: $green-900;
$btn-white-space: nowrap;
$btn-focus-box-shadow: none;
$btn-border-radius: 0.5rem;

// Button - small
$btn-border-radius-sm: $btn-border-radius;
$btn-font-size-sm: 0.875rem;
$btn-padding-y-sm: 0.155rem; // make it 28px height with 14px font size

// Button - medium
$btn-font-size: 0.875rem;

// Buttom - large
$btn-border-radius-lg: $btn-border-radius;
$btn-font-size-lg: 1rem;

// Forms
$form-label-font-weight: bold;
$form-label-font-weight: 500;
$form-label-font-size: 0.875rem;
$form-label-color: $black;
$form-text-color: $gray-800;
$form-check-input-border: 1px solid rgba($gray-400, 1);
$form-label-margin-bottom: var(--space-label-input);
$input-focus-box-shadow: 0 0 0 4px rgba(165, 169, 177, 0.25);
$form-select-focus-box-shadow: $input-focus-box-shadow;
$input-focus-border-color: $gray-700;
$input-placeholder-color: $gray-700;
$input-btn-focus-color: transparentize($input-focus-border-color, 0.25);
$input-bg: var(--bs-input-bg-color);
$input-border-color: var(--bs-gray-400);
$input-border-radius: 0.5rem;

// Forms - small
$input-font-size-sm: 0.875rem;
$input-border-radius-sm: $input-border-radius;
$input-padding-y-sm: 0.155rem; // 28px of height with font-size 14px;

// Forms - medium
$input-font-size: 0.875rem;

// Forms - large
$input-font-size-lg: 1rem;
$input-border-radius-lg: $input-border-radius;

// table
$table-group-separator-color: #dddddd; // keep as it was

// alert
$alert-border-radius: 8px;
$alert-border-width: 1px;

// z-index
$zindex-dropdown: 1060;

// Shadows
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$shadow-low:
  0 1px 3px 0 rgba(23, 24, 28, 0.25),
  0 2px 1px 0 rgba(23, 24, 28, 0.1),
  0 1px 1px 0 rgba(23, 24, 28, 0.15); // Defined in figma but currently not used
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$shadow-medium:
  0 7px 8px 0 rgba(23, 24, 28, 0.25),
  0 5px 22px 0 rgba(23, 24, 28, 0.1),
  0 12px 17px 0 rgba(23, 24, 28, 0.15);
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$shadow-high:
  0 11px 15px 0 rgba(23, 24, 28, 0.25),
  0 9px 46px 0 rgba(23, 24, 28, 0.1),
  0 24px 38px 0 rgba(23, 24, 28, 0.15);

.btn {
  --bs-btn-font-weight: 500;
}

.dropdown-menu {
  --bs-dropdown-min-width: 200px;
  --bs-dropdown-color: var(--bs-gray-800);
  --bs-dropdown-border-color: var(--bs-gray-400);
  --bs-dropdown-link-color: var(--bs-gray-800);
  --bs-dropdown-link-hover-color: var(--bs-gray-800);
  --bs-dropdown-link-hover-bg: var(--bg-light-hover);
  --bs-dropdown-link-active-color: var(--bs-gray-800);
  --bs-dropdown-link-active-bg: var(--bg-light-hover);
  --bs-dropdown-link-disabled-color: #a3a4a9;
  --bs-dropdown-box-shadow: #{$shadow-medium};
  --bs-dropdown-divider-bg: #{$border-base};
  // Setting --bs-dropdown-box-shadow should be enough but it's not.
  // Must be a bug in Bootstrap so the following line can be removed once it's fixed.
  box-shadow: $shadow-medium;
}

.library .navbar .navbar-nav {
  --bs-navbar-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.25rem;
}

// Spacers
$custom-spacers: (
  // We're not removing bootstrap's helpers, this means that the library wordpress header should work out of the box as we didn't override them there
  // We should think carefully about adding values here. If needed in only a few places, add it on spacings.scss instead
  // Our spacers
  s1: var(--space-1),
  s2: var(--space-2),
  s4: var(--space-4),
  s8: var(--space-8),
  s12: var(--space-12),
  s16: var(--space-16),
  s24: var(--space-24),
  s32: var(--space-32),
  s40: var(--space-40),
  s48: var(--space-48),
  s64: var(--space-64),
  // s72: var(--space-72), // Not added because usage is not enogh, single class added on spacings file
  // s80: var(--space-80), // Not added because usage is not enough. single class added on spacings file
);

$custom-negative-spacers: (
  ns1: calc(-1 * var(--space-1)),
  ns2: calc(-1 * var(--space-2)),
  ns4: calc(-1 * var(--space-4)),
  ns8: calc(-1 * var(--space-8)),
  ns12: calc(-1 * var(--space-12)),
  ns16: calc(-1 * var(--space-16)),
  ns24: calc(-1 * var(--space-24)),
);

$position-values: (
  0: 0,
  small: map-get($custom-spacers, 2),
  50: 50%,
  100: 100%,
);

// Tooltip
$tooltip-opacity: 1;

// Offcanvas
$offcanvas-padding-y: 32px;
$offcanvas-padding-x: 20px;
$offcanvas-horizontal-width: 320px;

// Accordion
$accordion-button-active-bg: $white;
$accordion-button-active-color: $carbon;
$accordion-border-color: $gray-400;
$accordion-padding-y: 8px;
$accordion-padding-x: 0;
$accordion-body-padding-y: 12px;
$accordion-button-focus-box-shadow: none !important;
$accordion-icon-width: 14px;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
$spacers: map-merge($spacers, $custom-spacers);
$negative-spacers: $custom-negative-spacers;
$theme-colors: map-merge($theme-colors, $custom-colors);

// Override Bootstrap variables that depend on another variable
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$header-font-family:
  'Fira Sans',
  #{$font-family-sans-serif};
/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$font-family-base:
  'Inter',
  #{$font-family-sans-serif};
@import '~bootstrap/scss/maps';

;@import "sass-embedded-legacy-load-done:6";