// Necessary to re-define the display classes with the $display-font-sizes redesign variable
// TODO: Remove this once we have display variables for the entire project
@import '../../../../../node_modules/bootstrap/scss/type';

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: $font-family-light, $font-family-sans-serif;
}

.display-2 {
  line-height: 1.1;
}

.display-4 {
  line-height: 1.4;
}

.line-hight-2 {
  line-height: 1.875;
}

;@import "sass-embedded-legacy-load-done:1878";