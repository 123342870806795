$btn-close-sm-width: 0.6em;
$btn-base-shadow: 0 0 0 4px;
$btn-primary-hover-background-color: $green-900;
$btn-red-hover-background-color: #ff6771;
$btn-red-active-background-color: #ff7079;
$btn-primary-focus-shadow-color: rgba(0, 199, 141, 0.25);
$btn-primary-focus-shadow: $btn-base-shadow $btn-primary-focus-shadow-color;
$btn-dark-focus-shadow-color: rgba(23, 24, 28, 0.25);
$btn-dark-focus-shadow: $btn-base-shadow $btn-dark-focus-shadow-color;
$btn-red-focus-shadow-color: rgba(255, 76, 88, 0.25);
$btn-red-focus-shadow: $btn-base-shadow $btn-red-focus-shadow-color;
$btn-secondary-focus-shadow-color: rgba(85, 88, 98, 0.25);
$btn-secondary-focus-shadow: $btn-base-shadow $btn-secondary-focus-shadow-color;

// Have default active border color to avoid defaulting to dark
html {
  --bs-btn-active-border-color: transparent;
}

.btn-close-sm,
.btn-close.btn-sm {
  @extend .btn-close;
  width: $btn-close-sm-width;
  height: $btn-close-sm-width;
  background-size: $btn-close-sm-width;
}

// Allows us to have a responsive button
.btn-md-lg {
  @include media-breakpoint-up(md) {
    @include button-size(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $btn-font-size-lg,
      $btn-border-radius-lg
    );
  }
}

.btn-rounded {
  --bs-btn-border-radius: 2rem;
  --bs-btn-padding-x: 1rem;
}

.btn {
  .btn-active-show {
    display: none;
  }
  .btn-active-hide {
    display: initial;
  }

  .btn-check:checked + & {
    .btn-active-show {
      display: initial;
    }
    .btn-active-hide {
      display: none;
    }
  }

  @each $size in 14, 16, 20, 24, 28, 35 {
    &.btn-#{$size} {
      width: #{$size}px;
      max-width: #{$size}px;
      height: #{$size}px;
      max-height: #{$size}px;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 0.5;
  }

  &.btn-rounded {
    &-icon {
      padding: 0;
      width: 16px;
      height: 16px;
      font-size: 12px;
    }
  }

  &-no-focus:focus {
    box-shadow: none;
    background-color: initial;
  }
}

.btn-primary {
  @include button-variant(
    $background: $primary,
    $border: $primary,
    $color: $white,
    $hover-background: $btn-primary-hover-background-color,
    $hover-border: $btn-primary-hover-background-color,
    $hover-color: $white,
    $active-background: $primary,
    $active-border: $primary,
    $active-color: $white,
    $disabled-background: $primary,
    $disabled-border: $primary,
    $disabled-color: $white
  );

  &:focus,
  &:active {
    box-shadow: $btn-primary-focus-shadow;
  }
}

.btn-outline-primary {
  @include button-variant(
    $background: transparent,
    $border: $primary,
    $color: $primary,
    $hover-background: $green-100,
    $hover-border: $btn-primary-hover-background-color,
    $hover-color: $primary,
    $active-background: $green-300,
    $active-border: $primary,
    $active-color: $green-700,
    $disabled-background: transparent,
    $disabled-border: $primary,
    $disabled-color: $primary
  );

  &:focus,
  &:active {
    box-shadow: $btn-primary-focus-shadow;
  }
}

.btn-black {
  @include button-variant(
    $background: $dark,
    $border: $dark,
    $color: $white,
    $hover-background: $gray-800,
    $hover-border: $gray-800,
    $hover-color: $white,
    $active-background: $dark,
    $active-border: $dark,
    $active-color: $white,
    $disabled-background: $dark,
    $disabled-border: $dark,
    $disabled-color: $white
  );

  &:not(.no-focus):focus {
    background: $gray-900;
    border-color: $gray-900;
    box-shadow: $btn-dark-focus-shadow;
  }

  &:not(.no-focus):active {
    box-shadow: $btn-dark-focus-shadow;
  }
}

.btn-danger {
  @include button-variant(
    $background: $red-500,
    $border: $red-500,
    $color: $white,
    $hover-background: $btn-red-hover-background-color,
    $hover-border: $btn-red-hover-background-color,
    $hover-color: $white,
    $active-background: $btn-red-active-background-color,
    $active-border: $btn-red-active-background-color,
    $active-color: $white,
    $disabled-background: $red-500,
    $disabled-border: $red-500,
    $disabled-color: $white
  );

  &:focus,
  &:active {
    box-shadow: $btn-red-focus-shadow;
  }
}

.btn-secondary {
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $gray-800,
    $hover-background: $gray-100,
    $hover-border: $gray-100,
    $hover-color: $gray-800,
    $active-background: $gray-200,
    $active-border: $gray-200,
    $active-color: $gray-900,
    $disabled-background: transparent,
    $disabled-border: transparent,
    $disabled-color: $gray-800
  );

  &:focus,
  &:active {
    //box-shadow: $btn-secondary-focus-shadow;
  }
}

.btn-secondary-danger {
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $gray-800,
    $hover-background: $red-100,
    $hover-border: $red-100,
    $hover-color: $red-500,
    $active-background: $red-300,
    $active-border: $red-300,
    $active-color: $red-700,
    $disabled-background: transparent,
    $disabled-border: transparent,
    $disabled-color: $gray-800
  );

  &:focus,
  &:active {
    box-shadow: $btn-red-focus-shadow;
  }
}

.btn-outline-secondary {
  @include button-variant(
    $background: transparent,
    $border: $gray-400,
    $color: $gray-800,
    $hover-background: $gray-100,
    $hover-border: $gray-700,
    $hover-color: $gray-800,
    $active-background: $gray-200,
    $active-border: $gray-700,
    $active-color: $gray-900,
    $disabled-background: transparent,
    $disabled-border: $gray-400,
    $disabled-color: $gray-800
  );

  &:focus,
  &:active {
    box-shadow: $btn-secondary-focus-shadow;
  }
}

.btn-outline-secondary-with-accent {
  @include button-variant(
    $background: transparent,
    $border: $gray-400,
    $color: $gray-700,
    $hover-background: $green-100,
    $hover-border: $btn-primary-hover-background-color,
    $hover-color: $primary,
    $active-background: $green-300,
    $active-border: $primary,
    $active-color: $dark,
    $disabled-background: transparent,
    $disabled-border: $primary,
    $disabled-color: $primary
  );

  &:focus,
  &:active {
    box-shadow: $btn-primary-focus-shadow;
  }
}

.btn-light-icon {
  &,
  &-transparent {
    // Overrides specific for buttons
    padding: 4px; // TODO: revisit when we have final spacer list
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
  }

  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $gray-600,
    $hover-background: $gray-100,
    $hover-border: $gray-100,
    $hover-color: $gray-700,
    $active-background: $gray-200,
    $active-border: $gray-200,
    $active-color: $gray-800,
    $disabled-background: transparent,
    $disabled-border: transparent,
    $disabled-color: $gray-600
  );

  &-transparent {
    @include button-variant(
      $background: transparent,
      $border: transparent,
      $color: $gray-600,
      $hover-background: transparent,
      $hover-border: transparent,
      $hover-color: $gray-700,
      $active-background: transparent,
      $active-border: transparent,
      $active-color: $gray-800,
      $disabled-background: transparent,
      $disabled-border: transparent,
      $disabled-color: $gray-600
    );
  }

  &.btn-sm {
    font-size: 12px;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.fake-disabled {
  // We want the button to appear enabled but to not do anything. Display only purposes
  pointer-events: none;
}

.btn-label {
  // To make the button appear as label. If used with `.btn` class and without any variant the element will also
  // not have any hover effects etc.
  --bs-btn-hover-color: var(--bs-btn-color);
  user-select: auto;
  cursor: auto;
}

.btn-group .icon-btn,
.btn-compact {
  :disabled {
    --bs-btn-disabled-border-color: transparent;
  }
}

.btn-group-lg {
  padding: 3px;
  border-radius: 12px;
  gap: 2px;
}
;@import "sass-embedded-legacy-load-done:1744";