:root {
  /*
   * Space
   */
  --space-0: 0;
  --space-1: 1px; // point5
  --space-2: 2px; // 0half
  --space-4: 4px; // 1
  --space-8: 8px; // 2
  --space-12: 12px; // 3
  --space-16: 16px; // 3point5, 16
  --space-24: 24px; // 4
  --space-32: 32px; // 5
  --space-40: 40px;
  --space-48: 48px;
  --space-64: 64px;
  --space-72: 72px;
  --space-80: 80px;
  --space-between-icon-and-text-compact: var(--space-4);
  --space-between-icon-and-text: var(--space-8);
  --space-between-buttons: var(--space-8);
  --space-inner-modal: var(--space-24);
  --space-between-form-fields-y: var(--space-24);
  --space-label-input: var(--space-8);

  /**
   * Colors
   */
  --bg-light-hover: var(--bs-gray-200);
  --bg-dark-translucent: rgba(
    var(--bs-gray-800-rgb),
    0.25
  ); // Bootstrap calculates rgb colors for us and places them on the root

  --text-base: var(--bs-carbon);
  --text-medium: var(--bs-gray-800);
  --text-muted: var(--bs-gray-700);
  --border-hover: var(--bs-gray-600);

  /**
  * Border radius
  */
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 16px;

  /**
  * Border Radius
  */
  --modal-border-radius: var(--border-radius-lg);

  /**
  * Navigation
  */
  --sl-sidebar-width: 240px;
  --sl-sidebar-bg: var(--bs-white);

  /**
  * Forms and Inputs
  */
  --sl-input-addon-size: 34px;

  /**
  * Plans and accounts
  */
  --paid-badge-bg: var(--bs-yellow-a25);
  --paid-badge-color: var(--bs-yellow-500);

  /**
  * Z-Indexes
  */
  --sl-z-index-header: 1035;
  --sl-z-index-modal: 1055;
  --sl-z-index-over-dropdown: 1061; // Bootstrap defines this as 1060;
  --sl-z-index-planner-player: 122;

  /**
  * Alerts
  */
  --bs-info-bg-subtle: var(--bs-blue-100);
  --bs-info-border-subtle: var(--bs-blue-300);

  /**
  * Planner variables
  */
  --block-border-width: 1px;
  --block-selected-border-color: var(--bs-gray-700);
  --group-block-selected-border-color: var(--bs-blue-700);
  --breakout-block-selected-border-color: var(--bs-orange-700);
  --group-block-color: var(--bs-blue-300);
  --breakout-block-color: var(--bs-orange-300);
  --group-border-color: var(--bs-blue-400);
  --breakout-border-color: #{mix($orange-300, $orange-500, 50%)};
  --group-inner-color: var(--bs-blue-100);
  --breakout-inner-color: var(--bs-orange-100);
  --dragging-indicator-color: var(--bs-blue-500);
  --overlap-border-color: var(--bs-red-300);
  --overlap-background-color: var(--bs-red-100);
  --buffer-border-color: var(--bs-gray-400);
  --buffer-background-color: var(--bs-gray-100);
  --note-border-color: var(--bs-yellow-300);
  --note-left-detail-color: var(--bs-yellow-500);
  --note-background-color: var(--bs-yellow-100);
  --planner-background: var(--bs-white);
  --column-resizer: var(--bs-blue-a25);
  --bottom-toolbar-height: 69px;
  // This value should not be overriden
  --base-block-border-color: var(--bs-gray-400);
  --session-multi-column-width: 360px;
  --add-block-menu-mh: 312px;

  /**
  * Richtext colors
  */
  // Keep these in sync with the list in helpers/sessions_helper.rb
  --rt-c-default: var(--bs-dark);
  --rt-c-red: #fa4b4b;
  --rt-c-orange: #f27216;
  --rt-c-yellow: #e0a81b;
  --rt-c-green: #08a868;
  --rt-c-blue: #0364d9;
  --rt-c-purple: #9335e6;
  --rt-c-pink: #da1a55;
  --rt-c-gray: var(--bs-gray-700);
  --rt-bg-default: var(--bs-white);
  --rt-bg-red: #ffe5e7;
  --rt-bg-orange: #ffeadb;
  --rt-bg-yellow: #fff2d1;
  --rt-bg-green: #e6faed;
  --rt-bg-blue: #f0f8ff;
  --rt-bg-purple: #f3e8fc;
  --rt-bg-pink: #fdf1f6;
  --rt-bg-gray: var(--bs-gray-200);
  --rt-border-default: var(--bs-white);
  --rt-border-red: var(--bs-red-300);
  --rt-border-orange: var(--bs-orange-300);
  --rt-border-yellow: var(--bs-yellow-300);
  --rt-border-green: var(--bs-green-300);
  --rt-border-blue: var(--bs-blue-300);
  --rt-border-purple: var(--bs-purple-300);
  --rt-border-pink: var(--bs-pink-300);
  --rt-border-gray: var(--bs-gray-300);

  /**
  * Blocks
  */
  --block-ai-border-color: var(--bs-purple-300);
  --block-ai-background-color: var(--bs-purple-100);
  --block-duration-time-number-color: var(--bs-carbon);
  --block-duration-time-letter-color: var(--bs-gray-800);
  --suggested-block-category-w: 240px;
  --suggested-block-category-h: 75px;

  /**
  * Sessions
  */
  --session-card-size: 300px;

  /**
  * Dashboard
  */
  --dashboard-spacing: var(--space-16);

  /**
  * Folders
  */
  --folder-card-height: 70px;
  --folder-card-margin: var(--space-12);
}

;@import "sass-embedded-legacy-load-done:2087";